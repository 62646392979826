<template>
  <div>
    <div class="row" v-if="statusCardsLoaded">
      <!-- Stats Cards -->
      <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.title">
        <stats-card
          :title="card.title"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        >
          <div class="routing" slot="footer" v-html="card.footer" @click="goToRoute(card)"></div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto" v-if="projectReservationBarChartLoaded">
        <card class="card-chart">
          <template slot="header">
            <h5 class="card-category">{{$t('analytics.projectsWithTools')}}</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-bullet-list-67 text-danger "></i> {{ this.projectWithToolCount }}
            </h3>
          </template>
          <div class="chart-area">
            <bar-chart
              :chart-data="projectReservationBarChart.chartData"
              :extra-options="projectReservationBarChart.extraOptions"
              :gradient-colors="projectReservationBarChart.gradientColors"
              :gradient-stops="projectReservationBarChart.gradientStops"
              :height="200"
            >
            </bar-chart>
          </div>
        </card>
      </div>
      <div class="col-md-6 ml-auto" v-if="userReservationBarChartLoaded">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('analytics.usersWithTools')}}</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-single-02 text-info "></i> {{ this.usersWithReservationCount }}
            </h3>
          </template>
          <div class="chart-area">
            <bar-chart
              style="height: 100%"
              :chart-data="userReservationBarChart.chartData"
              :gradient-stops="userReservationBarChart.gradientStops"
              :extra-options="userReservationBarChart.extraOptions"
            >
            </bar-chart>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto" v-if="toolStatusPieChartLoaded">
        <card class="card-chart card-chart-pie">
          <h5 slot="header" class="card-category">{{$t('analytics.toolsByStatus')}}</h5>
          <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="toolStatusPieChart.chartData"
                  :extra-options="toolStatusPieChart.extraOptions"
                  :height="120"
                >
                </pie-chart>
              </div>
            </div>
            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons  icon-settings text-warning "></i> {{ this.totalTools }}
              </h4>
              <p class="category">{{$t('analytics.totalTools')}}</p>
              <!-- Legend -->
              <div class="legend">
                <ul class="list-inline" v-for="(row, index) in legendRows" :key="index">
                  <li class="list-inline-item" v-for="item in row" :key="item.label">
                    <span class="legend-color" :style="{ backgroundColor: item.color }"></span>
                    <span class="legend-label">{{ item.label }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
//import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';
import PieChart from '../../components/Charts/PieChart';
import config from '../../config';
import * as chartConfigs from '../../components/Charts/config';
import StatsCard from 'src/components/Cards/StatsCard';
import api from "../../services/api"

export default {
  components: {
    //LineChart,
    BarChart,
    PieChart,
    StatsCard
  },
  data() {
    return {
      userCount: 0,
      totalTools: 0,
      toolStatusData: [],
      toolStatusPieChartLoaded: false,
      toolStatusPieChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Statuses',
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ['#344675', '#42b883', '#1d8cf8', '#fd5d93', '#ff8a76', '#00d6b4'],
              borderWidth: 0,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.pieChartOptions
      },
      projectStatusData: [],
      projectReservationBarChartLoaded: false,
      projectReservationBarChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Reservations',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.barChartOptionsGradient,
        gradientColors: config.colors.purpleGradient,
        gradientStops: [1, 0]
      },
      usersWithReservationCount: 0,
      userReservationStatusData: [],
      userReservationBarChartLoaded: false,
      userReservationBarChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Reservations',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.barChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      statusCardsLoaded: false,
      statsCards: [],
      toolGroupCount: 0,
      projectWithToolCount: 0,
      toolGroupDataLoaded: false
    };
  },
  computed: {
    legendRows() {
      const rows = [];
      for (let i = 0; i < this.legendItems.length; i += 2) {
        rows.push(this.legendItems.slice(i, i + 2));
      }
      return rows;
    },
    legendItems() {
      return this.toolStatusPieChart.chartData.datasets[0].backgroundColor.map((color, index) => {
        return {
          color,
          label: this.toolStatusPieChart.chartData.labels[index]
        };
      });
    }
  },
  watch: {
    toolGroupDataLoaded: 'populateStatsCards',
    userReservationBarChartLoaded: 'populateStatsCards',
    projectReservationBarChartLoaded: 'populateStatsCards',
    toolStatusPieChartLoaded: 'populateStatsCards',
  },
  created() {
    this.fetchToolGroupData(),
    this.fetchToolStatus(),
    this.fetchProjectStatus(),
    this.fetchReservationStatus()
  },
  methods: {
    fetchToolGroupData() {
      let toolGroupUrl = "ToolGroup?currentPage=1&pageSize=1"
      api.get(toolGroupUrl)
        .then(response => {
          this.toolGroupCount = response.data.totalItems;
          this.toolGroupDataLoaded = true;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: `Unable to fetch users`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    fetchToolStatus() {
      let toolStatusUrl = "/Analytics/Tools/ToolStatus";
      api.get(toolStatusUrl)
        .then(response => {
          this.toolStatusData = response.data;
          this.toolStatusData.forEach(item => {
            this.toolStatusPieChart.chartData.labels.push(this.getStatusTranslation(item.toolStatus));
            this.toolStatusPieChart.chartData.datasets[0].data.push(item.count);
            this.totalTools += item.count;
          });
          this.toolStatusPieChartLoaded = true;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: `Unable to fetch tool statuses`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    fetchProjectStatus() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
      const day = String(date.getDate()).padStart(2, '0');
      let today = `${year}-${month}-${day}`;
      let toolStatusUrl = `/Analytics/Projects/Series?fromDate=${today}&toDate=${today}&excludeZeroCounts=true`;
      api.get(toolStatusUrl)
        .then(response => {
          let min = 0;
          let max = 0;
          this.projectStatusData = response.data;
          this.projectStatusData.forEach(item => {
            if (item.data.length !== 0) {
              this.projectReservationBarChart.chartData.labels.push(item.project);
              this.projectReservationBarChart.chartData.datasets[0].data.push(item.data[0].toolCount);
              this.projectWithToolCount++;
              max = item.data[0].toolCount > max ? item.data[0].toolCount : max;
              min = item.data[0].toolCount < min ? item.data[0].toolCount : min;
            }
            else {
              this.projectWithoutToolCount++;
            }
          });

          min = min < 10 ? min : min - 5;
          this.projectReservationBarChart.extraOptions = {
            ...chartConfigs.barChartOptionsGradient,
            scales: {
              yAxes: [
                {
                  ...chartConfigs.barChartOptionsGradient.scales.yAxes[0],
                  ticks: {
                    ...chartConfigs.barChartOptionsGradient.scales.yAxes[0].ticks,
                    suggestedMin: min,
                    suggestedMax: max + 5,
                  },
                },
              ],
              xAxes: chartConfigs.barChartOptionsGradient.scales.xAxes,
            }
          };
          this.projectReservationBarChartLoaded = true;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: `Unable to fetch project statuses`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    fetchReservationStatus() {
      let toolReservationsUrl = "/Analytics/Users/ToolReservations";
      api.get(toolReservationsUrl)
        .then(response => {
          let min = 0;
          let max = 0;
          this.userReservationStatusData = response.data;
          this.userReservationStatusData.forEach(item => {
            if (item.toolCount !== 0) {
              this.userReservationBarChart.chartData.labels.push(item.user);
              this.userReservationBarChart.chartData.datasets[0].data.push(item.toolCount);
              this.usersWithReservationCount++;
              max = item.toolCount > max ? item.toolCount : max;
              min = item.toolCount < min ? item.toolCount : min;
            }
            this.userCount++;
          });
          min = min < 10 ? min : min - 5;
          this.userReservationBarChart.extraOptions = {
            ...chartConfigs.barChartOptions,
            scales: {
              yAxes: [
                {
                  ...chartConfigs.barChartOptions.scales.yAxes[0],
                  ticks: {
                    ...chartConfigs.barChartOptions.scales.yAxes[0].ticks,
                    suggestedMin: min,
                    suggestedMax: max + 5,
                  },
                },
              ],
              xAxes: chartConfigs.barChartOptions.scales.xAxes,
            }
          };
          this.userReservationBarChartLoaded = true;
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: `Unable to fetch tool statuses: ${error.message}`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    populateStatsCards() {
      if (this.userReservationBarChartLoaded && this.projectReservationBarChartLoaded && this.toolStatusPieChartLoaded && this.toolGroupDataLoaded) {
        this.statsCards = [
        {
          title: `${this.userCount}`,
          subTitle: this.$t('analytics.totalUsers'),
          type: 'info',
          icon: 'tim-icons icon-single-02',
          footer: '<i class="tim-icons icon-tap-02"></i> ' + this.$t('analytics.reviewNow'),
          routingType: 'Users'
        },
        {
          title: `${this.projectStatusData.length}`,
          subTitle: this.$t('analytics.totalProjects'),
          type: 'warning',
          icon: 'tim-icons icon-bullet-list-67',
          footer: '<i class="tim-icons icon-tap-02"></i> ' + this.$t('analytics.reviewNow'),
          routingType: 'Projects'
        },
        {
          title: `${this.totalTools}`,
          subTitle: this.$t('analytics.totalTools'),
          type: 'primary',
          icon: 'tim-icons icon-settings',
          footer: '<i class="tim-icons icon-tap-02"></i> ' + this.$t('analytics.reviewNow'),
          routingType: 'Tools'
        },
        {
          title: `${this.toolGroupCount}`,
          subTitle: this.$t('analytics.totalCars'),
          type: 'danger',
          icon: 'tim-icons icon-delivery-fast',
          footer: '<i class="tim-icons icon-tap-02"></i> ' + this.$t('analytics.reviewNow'),
          routingType: 'Cars'
        },
      ];

      this.statusCardsLoaded = true;
      }
    },
    getStatusTranslation(status)
    {
       switch (status) {
            case "Free":
              return this.$t('toolStatus.free')
            case "In Use":
              return this.$t('toolStatus.inUse')
            case "Broken":
              return this.$t('toolStatus.broken')
            case "Out of Use":
              return this.$t('toolStatus.outOfUse')
            case "Loaned":
              return this.$t('toolStatus.loaned')
            case "Rented":
              return this.$t('toolStatus.rented')
            default:
              return 'N/A'
          }
    },
    goToRoute(card) {
      this.$router.push({ name: card.routingType });
    }
  }
};
</script>
<style scoped>
  .legend {
    display: flex;
    flex-direction: column;
  }
  .legend-row {
    display: flex;
    justify-content: space-between;
  }
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .legend-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .legend-label {
    display: inline-block;
  }
  .routing {
    cursor: pointer;
  }
  .white-content .routing:hover {
  background-color: #f4f5fa;
}
  .routing:hover {
    background-color: #5b5b6a;
  }



</style>
