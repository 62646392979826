<template>
	<div>
		<div class="row">
			<div class="col-md-12 mr-auto ml-auto" v-if="costRegistrationLoaded">
				<card :title="$t('sidebar.costReport')">
					<base-input>
						<el-input
							type="search"
							class="mb-3 search-input from-group"
							clearable
							prefix-icon="el-icon-search"
							:placeholder="$t('common.searchRecords')"
							v-model="searchQuery"
							aria-controls="datatables"
						>
						</el-input>
					</base-input>
					<div class="calendar d-flex align-items-center">
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.from')"
								v-model="fromDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
						<span class="mx-2">-</span>
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.to')"
								v-model="toDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
					</div>
					<template v-if="isMobile">
						<div v-for="(project, projectId) in groupedReservations" :key="projectId">
							<collapse v-model="activeNames">
								<collapse-item :name="projectId">
									<template #title class="collapse-title">
										<span class="project-title">{{ project.name }}</span>
										<span class="project-details"> {{ $t('common.totalAmount') }}: {{ project.totalRevenue }} kr </span>
									</template>
									<div v-for="(tool, toolId) in project.tools" :key="toolId">
										<p>{{ tool.reservations[0].tool.name }}</p>
										<div v-for="reservation in tool.reservations" :key="reservation.id" class="item-tool">
											<span class="newline">
												{{ new Date(reservation.reservedTimestamp).toLocaleDateString() }} - {{ new Date(reservation.unreservedTimestamp).toLocaleDateString() }}
											</span>
											<span class="newline" v-if="reservation.externalUser">
												{{ $t('tools.rented') }} {{$t('tools.loanedRentedTo')}} {{ reservation.externalUser }}
											</span>
											<span class="newline">
												<i>{{ reservation.totalPrice == null ? 0 : reservation.totalPrice }} kr</i>
											</span>
										</div>
									</div>
									<base-button class="download-button" type="standard" @click="generatePDF(project)">
										{{ $t('common.download') }}
									</base-button>
								</collapse-item>
							</collapse>
						</div>
					</template>
					<template v-if="!isMobile">
						<div v-for="(project, projectId) in groupedReservations" :key="projectId">
							<collapse v-model="activeNames">
								<collapse-item :title="`${project.name} - ${$t('common.totalAmount')}: ${project.totalRevenue} kr`" :name="projectId">
									<div v-for="(tool, toolId) in project.tools" :key="toolId" class="item-tool">
										{{ tool.reservations[0].tool.name }}
										<div v-for="reservation in tool.reservations" :key="reservation.id" class="item-tool-dates">
											{{ new Date(reservation.reservedTimestamp).toLocaleDateString() }} - 
											{{ new Date(reservation.unreservedTimestamp).toLocaleDateString() }}
											<span v-if="reservation.externalUser">
												: {{ $t('tools.rented') }} {{$t('tools.loanedRentedTo')}} {{ reservation.externalUser }}
											</span>
											<span class="item-tool-dates-amount">
												{{ reservation.totalPrice == null ? 0 : reservation.totalPrice }} kr
											</span>
										</div>
									</div>
									<base-button class="download-button" type="standard" @click="generatePDF(project)">
										{{ $t('common.download') }}
									</base-button>
								</collapse-item>
							</collapse>
						</div>
					</template>
				</card>
			</div>
		</div>
	</div>
</template>
<script>

import api from "../../services/api"
import { DatePicker } from 'element-ui';
import { Collapse, CollapseItem } from 'src/components';
import jsPDF from 'jspdf';

export default {
	components: {
		[DatePicker.name]: DatePicker,
		Collapse,
    	CollapseItem
	},
	data() {
		return {
			activeNames: ['1'],
			toDate: null,
			fromDate: null,
			reservationData: [],
			customerInformation: {},
			costRegistrationLoaded: false,
			searchQuery: '',
			isMobile: window.innerWidth <= 768
		};
	},
	computed: {
		groupedReservations() {
			let grouped = this.reservationData.reduce((grouped, reservation) => {
				let projectId = reservation.project?.id || "reservationsNotLinkedToProject";
				let toolId = reservation.tool.id;
				if (!grouped[projectId]) {
					grouped[projectId] = {};
				}
				if (!grouped[projectId][toolId]) {
					grouped[projectId][toolId] = [];
				}
				grouped[projectId][toolId].push(reservation);
				return grouped;
			}, {});

			for (let projectId in grouped) {
				let projectName = null;
				let projectTotalRevenue = 0;
				let tools = [];

				for (let toolId in grouped[projectId]) {
					let reservations = grouped[projectId][toolId];
					projectName = reservations[0].project ? reservations[0].project.name : this.$t('tools.rented');
					let totalRevenue = reservations.reduce((total, reservation) => total + reservation.totalPrice, 0);
					tools.push({
					toolId: toolId,
					reservations: reservations,
					totalRevenue: totalRevenue
					});
					projectTotalRevenue += totalRevenue;
				}

				grouped[projectId] = {
					tools: tools,
					totalRevenue: projectTotalRevenue,
					name: projectName
				};
			}

			let sortedGrouped = Object.keys(grouped)
			.sort((a, b) => a === "reservationsNotLinkedToProject" ? 1 : b === "reservationsNotLinkedToProject" ? -1 : 0)
			.reduce((sorted, key) => {
				sorted[key] = grouped[key];
				return sorted;
			}, {});

			// Filter based on search query
			if (this.searchQuery) {
				sortedGrouped = Object.fromEntries(
					Object.entries(sortedGrouped).filter(([key, value]) => {
						if (value.name) {
							return value.name.toLowerCase().includes(this.searchQuery.toLowerCase());
						}
					})
				);
			}
			return sortedGrouped;
		}
	},
	created() {
		window.addEventListener('resize', this.updateIsMobile);
		this.initializeDateRange();
		this.fetchCostRegistration();
		this.fetchCustomerInformation();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateIsMobile);
	},
	methods: {
		initializeDateRange() {
			const today = new Date();
			this.toDate = today;
			this.fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
		},
		fetchCostRegistration() {
			const fetchReservations = async (url) => {
				let allReservations = [];
				let currentPage = 1;
				let totalPages = 1;

				while (currentPage <= totalPages) {
					const response = await api.get(`${url}&currentPage=${currentPage}`);
					const data = response.data;

					allReservations = allReservations.concat(data.result.filter(item => item.totalPrice !== 0 && item.totalPrice !== null));

					currentPage = data.currentPage + 1;
					totalPages = data.totalPages;
				}
				return allReservations;
			};
			
			let start = this.formatDate(this.fromDate);
			let end = this.formatDate(this.toDate);
			const reservationUrl = `/Tool/Reservations?forCurrentUser=false&unreserved=true&fromDate=${start}&toDate=${end}&pageSize=100`;
			const externalReservationUrl = `/Tool/Reservations/External?forCurrentUser=false&unreserved=true&fromDate=${start}&toDate=${end}&pageSize=100`;
			Promise.all([fetchReservations(reservationUrl), fetchReservations(externalReservationUrl)])
				.then(([internalReservations, externalReservations]) => {
					this.reservationData = internalReservations.concat(externalReservations.filter(item => item.rented));
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: `Unable to fetch reservations`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				})
				.finally(() => {
					this.costRegistrationLoaded = true;
				});
		},
		formatDate(date) {
			let d = new Date(date);
			let year = d.getFullYear();
			let month = String(d.getMonth() + 1).padStart(2, '0');
			let day = String(d.getDate()).padStart(2, '0');

			return `${year}-${month}-${day}`;
		},
		fetchCustomerInformation() {
			let customerUrl = `/Customers/Me`
			api.get(customerUrl)
				.then(response => {
					this.customerInformation = response.data.value;
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: this.$t('common.unableToRetrieveData'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 2000,
					});
				});
		},
		generatePDF(project) {
			const doc = new jsPDF();
			doc.setFont('times', 'normal');
			const textXPosition = 70;
			if (this.customerInformation.logoBytes === null && this.customerInformation.identificationNumber === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(12);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 30);
				doc.setFontSize(10);
			}
			else if (this.customerInformation.logoBytes === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(12);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 30);
				doc.setFontSize(10);
				doc.text(this.customerInformation.identificationNumber, textXPosition, 40);
			}
			else if (this.customerInformation.identificationNumber === null) {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				doc.addImage(logoDataUrl, 'PNG', 10, 10, 50, 50);
				// Add company Name, SSN and a header line
				doc.setFontSize(12);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 30);
				doc.setFontSize(10);
			}
			else {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				doc.addImage(logoDataUrl, 'PNG', 10, 10, 50, 50);

				// Add company Name, SSN and a header line
				doc.setFontSize(12);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 30);
				doc.setFontSize(10);
				doc.text(this.customerInformation.identificationNumber, textXPosition, 40);
			}
			
			doc.line(10, 72, 200, 72); // Draw a line

			// Starting Y position for the text
			let currentYPosition = 80; 
			const pageHeight = doc.internal.pageSize.height;
			const bottomMargin = 10; // Margin at the bottom of the page

			// Add the information from the collapse item
			// You'll need to format this content appropriately
			const projectInfo = this.formatProjectInfoForPDF(project);
			const lines = projectInfo.split('\n');

			// Add each line of the project info to the PDF
			lines.forEach(line => {
				if (line !== '---') { // Check if the line is not a placeholder for the horizontal line
					// Check if the current position exceeds the page height
					if (currentYPosition >= pageHeight - bottomMargin) {
						doc.addPage();
						currentYPosition = 10; // Reset Y position for the new page
					}
					doc.text(line, 10, currentYPosition);
					currentYPosition += 5; // Increase Y position for the next line
				} 
				else {
					// Ensure there's enough space for the line
					if (currentYPosition >= pageHeight - bottomMargin) {
						doc.addPage();
						currentYPosition = 10; // Reset Y position for the new page
					}
					doc.line(10, currentYPosition - 5, 200, currentYPosition - 5); // Draw a line
					currentYPosition += 5; // Increase Y position for the next line
				}
			});

			// Save the PDF
			doc.save(`${project.name}.pdf`);
		},
		formatProjectInfoForPDF(project) {
			let formattedText = '';
			const pageWidth = 210; // A4 width in mm
			const rightMargin = 10; // Right margin in mm
			const doc = new jsPDF();

			// Set the font to Times New Roman
			doc.setFont('times', 'normal');
			doc.setFontSize(10);

			// Calculate the maximum number of characters that fit in a line
			const maxLineLength = pageWidth - rightMargin;
			const charWidth = doc.getStringUnitWidth(' ') * doc.internal.getFontSize() / doc.internal.scaleFactor;
			const maxChars = maxLineLength / charWidth;
			let totalRevenue = project.totalRevenue;
			for (let tool of project.tools) {
				// Tool name
				formattedText += `${tool.reservations[0].tool.name}\n`;

				// Reservations for the tool
				tool.reservations.forEach(reservation => {
				let reservedDate = new Date(reservation.reservedTimestamp).toLocaleDateString();
				let unreservedDate = new Date(reservation.unreservedTimestamp).toLocaleDateString();
				let priceCalculation = reservation.totalPrice;
				let priceText = `${priceCalculation.toFixed(2)} kr`;
				let priceWidth = doc.getStringUnitWidth(priceText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
				let pricePosition = maxChars - (priceWidth / charWidth);
				formattedText += `     ${reservedDate} - ${unreservedDate}`.padEnd(pricePosition - 45, ' ') + priceText + '\n';
				});

				// Empty line between each tool
				formattedText += '\n';
			}

			// Placeholder for the line above the total revenue
			formattedText += '---\n';

			let totalText = `${this.$t('common.totalAmount')}: ${totalRevenue.toFixed(2)} kr`;
			let totalWidth = doc.getStringUnitWidth(totalText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
			let totalPosition = maxChars - (totalWidth / charWidth) - 30;
			formattedText += ' '.repeat(totalPosition) + totalText;

			return formattedText;
		},
		updateIsMobile() {
			this.isMobile = window.innerWidth <= 768;
		}
	}
};
</script>
<style scoped>
	.calendar {
		padding-bottom: 15px;
	}
	.item-tool {
		padding-left: 20px;
		margin-bottom: 10px;
	}

	.item-tool-dates {
		padding-left: 40px;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.item-tool-dates-amount {
		float: right;
		padding-right: 50px;
	}

	.download-button {
		width: 100%
	}

	.project-details { 
		display: block;
	}
	.newline { 
		display: block;
	}
</style>
